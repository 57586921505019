@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";

.hero {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-family: $font_regular_1;
  margin-top: 49px;
  @media screen and (min-width: 700px) {
    margin-top: 57px;
  }
  @media screen and (min-width: 1156px) {
    margin-top: 72px;
  }
  &_video {
    width: 100%;
  }
  &_banner_box_img {
    width: 100%;
    height: 47vh;
    background-image: url("../../images/cabecera_sin_franja_blanca.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 383px;
    @media screen and (min-width: 446px) {
      height: 50vh;
    }
    @media screen and (min-width: 562px) {
      min-height: 480px;
      height: 60vh;
    }
    @media screen and (min-width: 1045px) {
      min-height: 530px;
      height: 65vh;
    }
    @media screen and (min-width: 1433px) {
      min-height: 612px;
      height: 75vh;
    }
    @media screen and (min-width: 1597px) {
      min-height: 693px;
      height: 85vh;
    }
  }
  &_banner_img {
    width: 100%;
    height: 100%;
  }
  &_img_plataforma {
    width: 100%;
    height: 100%;
  }
  &_img_plataforma_box {
    width: 89%;
    padding-right: 5px;
    padding-left: 5px;
    @media screen and (min-width: 446px) {
      width: 84%;
      padding-top: 10px;
    }
    @media screen and (min-width: 700px) {
      width: 88%;
      padding-top: 10px;
    }
    @media screen and (min-width: 874px) {
      width: 85%;
      padding-top: 0px;
    }
    @media screen and (min-width: 1187px) {
      width: 80%;
      padding-top: 0px;
    }
  }
  &_banner {
    opacity: 0.9;
    background-color: white;
    padding-top: 15px;
    padding-right: 2px;
    padding-left: 2px;
    width: 50.2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    z-index: 1;
    color: grey;
    font-size: rem(14);
    padding-bottom: 50px;
    @media screen and (min-width: 446px) {
      padding-top: 10px;
    }
    @media screen and (min-width: 562px) {
      padding-top: 13px;
    }
    @media screen and (min-width: 661px) {
      padding-top: 15px;
    }
    @media screen and (min-width: 700px) {
      left: 18%;
      width: 25%;
      padding-top: 10px;
      padding-bottom: 20px;
    }
    @media screen and (min-width: 799px) {
      width: 25%;
      padding-top: 9px;
    }
    @media screen and (min-width: 874px) {
      padding-top: 17px;
    }
    @media screen and (min-width: 1151px) {
      padding-top: 25px;
      padding-bottom: 26px;
    }
    @media screen and (min-width: 1257px) {
      padding-top: 30px;
      padding-bottom: 38px;
      font-size: rem(17);
    }
    @media screen and (min-width: 1271px) {
      padding-top: 37px;
      padding-bottom: 38px;
      font-size: rem(17);
    }
    @media screen and (min-width: 1433px) {
      padding-top: 40px;
      padding-bottom: 40px;
      font-size: rem(19);
    }
    @media screen and (min-width: 1698px) {
      padding-top: 38px;
      padding-bottom: 44px;
      font-size: rem(21);
    }
  }
  &_text_1 {
    @media screen and (min-width: 700px) {
      position: absolute;
      text-transform: uppercase;
      font-family: $font_bold_1;
      z-index: 1;
      left: 25%;
      padding-top: 0px;
      top: 21%;
      color: grey;
      margin-bottom: 4.5px;
      padding-right: 7.5px;
      padding-left: 7.5px;
    }
    @media screen and (min-width: 799px) {
      padding-top: 25px;
      padding-right: 15px;
      padding-left: 15px;
      font-size: rem(16);
    }
    @media screen and (min-width: 874px) {
      padding-top: 40px;
      padding-right: 15px;
      padding-left: 15px;
    }
    @media screen and (min-width: 1036px) {
      padding-top: 20px;
    }
    @media screen and (min-width: 1058px) {
      font-size: rem(17);
    }
    @media screen and (min-width: 1194px) {
      font-size: rem(18);
    }
    @media screen and (min-width: 1271px) {
      padding-top: 25px;
      padding-right: 25px;
      padding-left: 25px;
    }
    @media screen and (min-width: 1433px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 1480px) {
      padding-top: 45px;
      font-size: rem(22);
    }
    @media screen and (min-width: 1597px) {
      padding-top: 80px;
    }
  }
  &_text_2 {
    @media screen and (min-width: 700px) {
      font-size: rem(16);
      text-transform: uppercase;
      color: white;
      font-family: $font_bold_1;
      left: 25%;
      padding-top: 0px;
      top: 24%;
      position: absolute;
      z-index: 1;
      margin-top: 2px;
      margin-bottom: 3.5px;
      padding-right: 3.5px;
      padding-left: 3.5px;
    }
    @media screen and (min-width: 1058px) {
      font-size: rem(17);
    }
    @media screen and (min-width: 1194px) {
      font-size: rem(18);
    }
    @media screen and (min-width: 1271px) {
      padding-right: 25px;
      padding-left: 25px;
    }
    @media screen and (min-width: 1433px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 1480px) {
      padding-top: 15px;
      font-size: rem(22);
    }
  }
}

.highlight {
  font-weight: 900;
}

.highlight_green {
  font-weight: 900;
  color: $main-color;
}
