@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";

.aviso {
  &_main {
    font-family: $font_regular_1;
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 17px;
    padding-left: 17px;
    padding-bottom: 17px;
    padding-top: 20px;
    margin-top: 25px;
    border-radius: 10px;
    background-color: rgba(226, 219, 219, 0.3);
    @media screen and (min-width: 700px) {
      margin-right: 80px;
      margin-left: 80px;
    }
    @media screen and (min-width: 1008px) {
      margin-right: 100px;
      margin-left: 100px;
      padding-right: 35px;
      padding-left: 35px;
      padding-bottom: 35px;
      padding-top: 40px;
    }
    @media screen and (min-width: 1086px) {
      margin-top: 35px;
    }
    @media screen and (min-width: 1248px) {
      margin-right: 200px;
      margin-left: 200px;
    }
  }
  &_main_title {
    color: $main-color;
    font-size: rem(30);
    padding-right: 22px;
    padding-left: 22px;
    margin-top: 70px;
    font-family: $font_bold_1;
    @media screen and (min-width: 700px) {
      padding-right: 0px;
      padding-left: 0px;
      margin-right: 55px;
      margin-left: 80px;
      font-size: rem(35);
      margin-top: 80px;
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(37);
      margin-top: 60px;
      margin-right: 100px;
      margin-left: 100px;
      margin-top: 90px;
    }
    @media screen and (min-width: 1248px) {
      margin-right: 200px;
      margin-left: 200px;
    }
  }
  &_secondary_title {
    color: rgb(141, 138, 138);
    font-size: rem(28);
    padding-bottom: 10px;
    font-family: $font_bold_1;
    @media screen and (min-width: 700px) {
      font-size: rem(33);
    }
  }
  &_text {
    color: $main-text-color;
    padding-bottom: 5px;
    font-size: rem(19);
    @media screen and (min-width: 500px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(20);
    }
  }
  &_link {
    text-decoration: none;
    color: $main-color;
    font-family: $font_bold_1;
  }
}

.aviso_link:hover {
  color: $main-text-color;
}

.aviso_link:active {
  color: $main-text-color;
}
