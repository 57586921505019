@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";

.cifras {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 25px;
  font-family: $font_bold_1;
  @media screen and (min-width: 700px) {
    padding-top: 60px;
    padding-bottom: 20px;
  }
  @media screen and (min-width: 1208px) {
    padding-top: 35px;
  }
  @media screen and (min-width: 1399px) {
    background-image: url("../../images/Mano 1_.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: start;
    margin-top: 30px;
  }
  &_link {
    text-decoration: none;
    cursor: pointer;
  }
  &_title {
    font-size: rem(29);
    color: grey;
    padding-bottom: 20px;
    @media screen and (min-width: 533px) {
      font-size: rem(31);
    }
    @media screen and (min-width: 600px) {
      font-size: rem(34);
    }
    @media screen and (min-width: 700px) {
      padding-bottom: 25px;
      font-size: rem(35);
    }
    @media screen and (min-width: 1024px) {
      padding-bottom: 25px;
      font-size: rem(36);
    }
  }
  &_data {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    &:hover {
      color: white;
    }
    @media screen and (min-width: 444px) {
      width: 70%;
    }
    @media screen and (min-width: 549px) {
      width: 100%;
    }
    @media screen and (min-width: 700px) {
      width: 100%;
    }
    @media screen and (min-width: 1208px) {
      padding-right: 10px;
    }
    @media screen and (min-width: 1399px) {
      justify-content: flex-end;
      align-items: flex-end;
    }
    @media screen and (min-width: 1407px) {
      padding-right: 20px;
    }
    @media screen and (min-width: 1794px) {
      padding-right: 60px;
    }
    &_box {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px solid $main_color;
      opacity: 0.8;
      padding-top: 30px;
      padding-bottom: 30px;
      height: 150px;
      width: 230px;
      border-radius: 10%;
      color: $main_color;
      @media screen and (min-width: 495px) {
        width: 270px;
      }
      @media screen and (min-width: 550px) {
        width: 40%;
      }
      @media screen and (min-width: 614px) {
        width: 40%;
        padding-top: 26.3px;
        padding-bottom: 26.3px;
        max-width: 300px;
      }
      @media screen and (min-width: 700px) {
        width: 40%;
      }
      @media screen and (min-width: 884px) {
        width: 300px;
      }
      @media screen and (min-width: 942px) {
        width: 285px;
      }
      @media screen and (min-width: 993px) {
        width: 30%;
      }
      @media screen and (min-width: 1127px) {
        width: 22%;
        padding-top: 28px;
        padding-bottom: 28px;
      }
      @media screen and (min-width: 1208px) {
        width: 20%;
        padding-top: 7px;
        padding-bottom: 7px;
      }
      @media screen and (min-width: 1277px) {
        width: 20%;
        padding-top: 18px;
        padding-bottom: 18px;
      }
      @media screen and (min-width: 1279px) {
        width: 20%;
        padding-top: 18px;
        padding-bottom: 18px;
      }
      @media screen and (min-width: 1399px) {
        width: 18%;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      @media screen and (min-width: 1407px) {
        width: 16.6%;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      @media screen and (min-width: 1448px) {
        padding-top: 10px;
        padding-bottom: 14px;
      }
      @media screen and (min-width: 1794px) {
        width: 15%;
      }
    }
    &_text1 {
      font-family: $font_bold_1;
      margin-bottom: 0px;
      font-weight: bolder;
      font-size: rem(50);
      @media screen and (min-width: 549px) {
        font-size: rem(42);
      }
      @media screen and (min-width: 993px) {
        font-size: rem(43);
      }
      @media screen and (min-width: 1127px) {
        font-size: rem(47);
      }
      @media screen and (min-width: 1399px) {
        font-size: rem(56);
      }
      @media screen and (min-width: 1407px) {
        font-size: rem(45);
      }
      &:hover {
        background-color: $main-color;
        color: white;
      }
    }
    &_text2 {
      margin-bottom: 0px;
      margin-top: -5px;
      color: grey;
      font-family: $font_regular_1;
      font-size: rem(16);
      position: relative;
      top: -8px;
      padding-right: 20px;
      padding-left: 20px;
      line-height: 1.3;
      @media screen and (min-width: 550px) {
        font-size: rem(18);
        margin-top: -1px;
      }
      @media screen and (min-width: 700px) {
        top: -5px;
        font-size: rem(21);
      }
      @media screen and (min-width: 993px) {
        font-size: rem(18);
      }
      @media screen and (min-width: 1024px) {
        font-size: rem(21);
      }
      @media screen and (min-width: 1127px) {
        font-size: rem(18);
      }
      @media screen and (min-width: 1399px) {
        font-size: rem(19);
      }
      @media screen and (min-width: 1401px) {
        font-size: rem(19);
        padding-right: 30px;
        padding-left: 30px;
      }
      @media screen and (min-width: 1407px) {
        font-size: rem(15);
      }
    }
    &_text3 {
      font-family: $font_bold_1;
      margin-bottom: 0px;
      padding-bottom: 0px;
      font-weight: 800;
      font-size: rem(30);
      color: $main_color;
      line-height: 1.1;
      @media screen and (min-width: 550px) {
        font-size: rem(35);
        line-height: 1;
        padding-top: 5px;
      }
      @media screen and (min-width: 563px) {
        padding-top: 10px;
        padding-top: 14px;
      }
      @media screen and (min-width: 614px) {
        padding-top: 5px;
      }
      @media screen and (min-width: 700px) {
        padding-top: 20px;
        /*         padding-bottom: 8px; */
        padding-left: 10px;
        padding-right: 10px;
      }
      @media screen and (min-width: 1024px) {
        font-size: rem(38);
      }
      @media screen and (min-width: 1127px) {
        padding-top: 5px;
      }
      @media screen and (min-width: 1208px) {
        padding-top: 20px;
      }
      @media screen and (min-width: 1277px) {
        padding-top: 5px;
      }
      @media screen and (min-width: 1279px) {
        padding-top: 10px;
      }
      @media screen and (min-width: 1327px) {
        font-size: rem(38);
      }
      @media screen and (min-width: 1395px) {
        padding-top: 8px;
      }
      @media screen and (min-width: 1396px) {
        padding-top: 20px;
      }
      @media screen and (min-width: 1399px) {
        padding-top: 25px;
      }
      @media screen and (min-width: 1407px) {
        padding-top: 16px;
        padding-bottom: 8px;
      }
      @media screen and (min-width: 1444px) {
        padding-top: 18px;
      }
      @media screen and (min-width: 1448px) {
        padding-top: 10px;
        margin-top: 10px;
        padding-bottom: 0px;
      }
      @media screen and (min-width: 1527px) {
        margin-top: 15px;
        padding-top: 18px;
      }
      @media screen and (min-width: 1794px) {
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 15px;
      }
      @media screen and (min-width: 1857px) {
        padding-right: 15px;
        padding-left: 15px;
      }
      &:hover {
        background-color: $main-color;
        color: white;
      }
    }
    &_text4 {
      margin-bottom: 10px;
      margin-right: 10px;
      margin-left: 10px;
      color: grey;
      font-family: $font_regular_1;
      font-size: rem(16);
      @media screen and (min-width: 550px) {
        font-size: rem(18);
        line-height: 1;
        margin-top: 0px;
        margin-bottom: 8px;
      }
      @media screen and (min-width: 563px) {
        padding-top: 15px;
      }
      @media screen and (min-width: 614px) {
        padding-top: 6px;
      }
      @media screen and (min-width: 700px) {
        padding-bottom: 14px;
      }
      @media screen and (min-width: 1024px) {
        font-size: rem(21);
      }
      @media screen and (min-width: 1127px) {
        font-size: rem(18);
        padding-bottom: 7px;
      }
      @media screen and (min-width: 1208px) {
        padding-bottom: 15px;
      }
      @media screen and (min-width: 1277px) {
        padding-top: 0px;
      }
      @media screen and (min-width: 1279px) {
        padding-bottom: 8px;
      }
      @media screen and (min-width: 1395px) {
        padding-bottom: 8px;
        font-size: rem(16);
      }
      @media screen and (min-width: 1399px) {
        padding-bottom: 5px;
        padding-top: 0px;
        font-size: rem(19);
      }
      @media screen and (min-width: 1407px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 1444px) {
        padding-top: 11px;
      }
      @media screen and (min-width: 1448px) {
        padding-bottom: 15px;
      }
      @media screen and (min-width: 1527px) {
        padding-bottom: 25px;
      }
    }
  }
  &_img {
    width: 100%;
    height: 100%;
  }
  &_list_item {
    width: 100px;
    height: 100px;
    border: 1px solid $main-color;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
  }
  &_text {
    color: $main-text-color;
    font-family: $font_regular_1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 25px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: rem(20);
    @media screen and (min-width: 700px) {
      padding-top: 30px;
    }
    @media screen and (min-width: 993px) {
      display: block;
      padding-right: 100px;
      padding-left: 100px;
      text-align: center;
    }
    @media screen and (min-width: 1399px) {
      padding-right: 0px;
      margin-left: 270px;
      font-size: rem(18);
    }
    @media screen and (min-width: 1407px) {
      margin-left: 310px;
    }
    @media screen and (min-width: 1533px) {
      margin-left: 330px;
    }
    @media screen and (min-width: 1625px) {
      margin-left: 360px;
    }
    @media screen and (min-width: 1794px) {
      margin-left: 440px;
    }
    &_box1 {
      text-align: center;
      padding-bottom: 20px;
      @media screen and (min-width: 700px) {
        padding-bottom: 0px;
      }
    }
  }
  &_text_2 {
    color: $main-text-color;
    font-family: $font_regular_1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: rem(20);
    @media screen and (min-width: 700px) {
      margin-top: 5px;
    }
    @media screen and (min-width: 993px) {
      display: block;
      padding-bottom: 20px;
    }
    @media screen and (min-width: 1127px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 1396px) {
      padding-bottom: 0px;
    }
    @media screen and (min-width: 1399px) {
      font-size: rem(18);
      margin-left: 280px;
      margin-top: 0px;
      padding-top: 5px;
    }
    @media screen and (min-width: 1407px) {
      margin-left: 400px;
    }
    @media screen and (min-width: 1533px) {
      margin-left: 430px;
    }
    @media screen and (min-width: 1625px) {
      margin-left: 460x;
    }
    @media screen and (min-width: 1794px) {
      margin-left: 520px;
    }
  }
  &_highlight_grey {
    color: grey;
    font-family: $font_bold_1;
  }
  &_highlight_green {
    color: $main-color;
    font-family: $font_bold_1;
  }
  &_box_1 {
    background-color: rgb(86, 83, 83);
    border-bottom: 7px solid $main-color;
    border-radius: 10px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    height: 165px;
    &:hover {
      background-color: $main-text-color;
      animation: fadeIn linear 0.5s;
    }
    @media screen and (min-width: 464px) {
      margin-right: 50px;
      margin-left: 50px;
    }
    @media screen and (min-width: 538px) {
      margin-right: 60px;
      margin-left: 60px;
    }
    @media screen and (min-width: 612px) {
      margin-right: 80px;
      margin-left: 80px;
    }
    @media screen and (min-width: 677px) {
      margin-right: 100px;
      margin-left: 100px;
    }
    @media screen and (min-width: 778px) {
      margin-right: 120px;
      margin-left: 100px;
    }
    @media screen and (min-width: 779px) {
      width: 340px;
      margin-right: 0px;
      margin-left: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      gap: 10px;
    }
    @media screen and (min-width: 826px) {
      width: 340px;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    @media screen and (min-width: 993px) {
      width: 415px;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    @media screen and (min-width: 1407px) {
      width: 429px;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    &_title {
      padding-right: 10px;
      font-size: rem(22);
      @media screen and (min-width: 700px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 778px) {
        font-size: rem(23);
        padding: 11.1px;
      }
    }
  }
  &_box_2 {
    background-color: rgb(86, 83, 83);
    border-bottom: 7px solid $main-color;
    border-radius: 10px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    height: 165px;
    &:hover {
      background-color: $main-text-color;
      animation: fadeIn linear 0.5s;
    }
    @media screen and (min-width: 464px) {
      margin-right: 50px;
      margin-left: 50px;
    }
    @media screen and (min-width: 538px) {
      margin-right: 60px;
      margin-left: 60px;
    }
    @media screen and (min-width: 612px) {
      margin-right: 80px;
      margin-left: 80px;
    }
    @media screen and (min-width: 677px) {
      margin-right: 100px;
      margin-left: 100px;
    }
    @media screen and (min-width: 779px) {
      width: 340px;
      margin-top: 20px;
      margin-right: 0px;
      margin-left: 10px;
      padding-top: 15px;
    }
    @media screen and (min-width: 826px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    @media screen and (min-width: 993px) {
      width: 415px;
    }
    &_title {
      padding-right: 10px;
      font-size: rem(22);
      @media screen and (min-width: 700px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 778px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 826px) {
        padding-top: 14px;
        padding-bottom: 14px;
      }
      @media screen and (min-width: 993px) {
        padding-right: 12px;
        padding-bottom: 12px;
      }
    }
  }
  &_link_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 779px) {
      flex-direction: row;
      margin-right: 20px;
      margin-left: 50px;
    }
    @media screen and (min-width: 1208px) {
      flex-direction: row;
      margin-right: 140px;
      margin-left: 140px;
    }
    @media screen and (min-width: 1399px) {
      flex-direction: row;
      margin-left: 400px;
      margin-right: 5px;
    }
    @media screen and (min-width: 1407px) {
      margin-left: 420px;
    }
    @media screen and (min-width: 1483px) {
      flex-direction: row;
      margin-left: 440px;
      margin-right: 5px;
    }
    @media screen and (min-width: 1533px) {
      margin-left: 460px;
    }
    @media screen and (min-width: 1625px) {
      margin-left: 510px;
    }
    @media screen and (min-width: 1794px) {
      margin-left: 620px;
    }
    &_1 {
      padding: 20px;
      padding-bottom: 0px;
      padding-top: 10px;
      @media screen and (min-width: 778px) {
        padding-top: 0px;
        padding-right: 0px;
      }
      @media screen and (min-width: 785px) {
        padding-left: 0px;
      }
    }
    &_2 {
      padding: 20px;
      padding-top: 0px;
      padding-bottom: 0px;
      @media screen and (min-width: 1019px) {
        padding: 20px;
      }
    }
  }
}

.highlight_green {
  color: $main-color;
  font-weight: 800;
}
.centered {
  text-align: center;
}

.big_title {
  @media screen and (min-width: 700px) {
    font-size: rem(33);
  }
  @media screen and (min-width: 1399px) {
    font-size: rem(37);
  }
  @media screen and (min-width: 1407px) {
    font-size: rem(34);
  }
}

#hover_box:hover * {
  background-color: $main-color;
  color: white;
}

#hover_box:hover {
  background-color: $main-color;
  color: white;
  font-family: $font_bold_1;
  animation: fadeIn forwards 0.5s;
}
