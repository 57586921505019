@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";
@import "../core/keyframes.scss";

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-bottom: 70px;
  padding-top: 20px;
  font-family: $font_regular_1;
  font-size: rem(16);
  @media screen and (min-width: 549px) {
    font-size: rem(17);
  }
  @media screen and (min-width: 700px) {
    margin-top: 80px;
    padding-top: 300px;
    background-image: url("../../images/Mano_footer.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &_heart {
    @media screen and (min-width: 700px) {
      position: relative;
      bottom: 106px;
    }
  }
  &__logos {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    @media screen and (min-width: 700px) {
      padding-top: 10px;
    }
    &_img {
      width: 260px;
      height: 70px;
      @media screen and (min-width: 700px) {
        width: 240px;
        height: 70px;
      }
    }
    @media screen and (min-width: 700px) {
      margin-bottom: 10px;
    }
  }
  &__list {
    font-family: $font_regular_1;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: -10px;
    @media screen and (min-width: 700px) {
      padding-top: 5px;
    }
    &_item {
      height: 33px;
    }
  }
  &__copy {
    font-family: $font_regular_1;
    color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    font-size: rem(15);
    margin-bottom: -3px;
    @media screen and (min-width: 549px) {
      font-size: rem(16);
    }
    &_box {
      padding-top: 5px;
      @media screen and (min-width: 700px) {
        padding-top: 10px;
      }
    }
  }
}

.footer__list_item_link {
  text-decoration: none;
  padding: 0px;
  color: grey;
  transition: 0.3s;
}

.footer__list_item_link:hover {
  color: $main-color;
}

.footer__list_item_link:active {
  color: $main-color;
}

.footer__list_item {
  position: relative;
  color: $main-color;
}

.footer__list_item:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $main-color;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footer__list_item:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
