@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";

.privacidad {
  &_main {
    font-family: $font_regular_1;
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 17px;
    padding-left: 17px;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 10px;
    background-color: rgba(226, 219, 219, 0.3);
    @media screen and (min-width: 700px) {
      margin-right: 80px;
      margin-left: 80px;
    }
    @media screen and (min-width: 901px) {
      padding-right: 35px;
      padding-left: 35px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @media screen and (min-width: 1008px) {
      margin-right: 100px;
      margin-left: 100px;
      padding-right: 35px;
      padding-left: 35px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @media screen and (min-width: 1248px) {
      margin-right: 200px;
      margin-left: 200px;
    }
  }
  &_main_title {
    color: $main-color;
    font-size: rem(30);
    padding-right: 22px;
    padding-left: 22px;
    padding-bottom: 15px;
    margin-top: 80px;
    font-family: $font_bold_1;
    @media screen and (min-width: 700px) {
      padding-right: 0px;
      padding-left: 0px;
      margin-right: 55px;
      margin-left: 80px;
      font-size: rem(36);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(38);
      margin-top: 90px;
      margin-right: 100px;
      margin-left: 100px;
    }
    @media screen and (min-width: 1248px) {
      margin-right: 200px;
      margin-left: 200px;
      margin-top: 110px;
      margin-bottom: 20px;
    }
  }
  &_secondary_title {
    color: rgb(141, 138, 138);
    font-size: rem(28);
    padding-bottom: 10px;
    font-family: $font_bold_1;
    @media screen and (min-width: 700px) {
      font-size: rem(28);
    }
  }
  &_tertiary_title {
    color: rgb(138, 135, 135);
    font-size: rem(24);
    padding-bottom: 10px;
    font-family: $font_bold_1;
    @media screen and (min-width: 700px) {
      font-size: rem(28);
    }
  }
  &_list {
    list-style: inside;
    color: $main-text-color;
    font-size: rem(19);
    @media screen and (min-width: 536px) {
      font-size: rem(21);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(18);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(20);
    }
  }
  &_text {
    color: $main-text-color;
    padding-bottom: 5px;
    font-size: rem(19);
    @media screen and (min-width: 536px) {
      font-size: rem(21);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(20);
    }
  }
  &_link {
    text-decoration: none;
    font-weight: 500;
    color: $main-color;
    font-family: $font_bold_1;
  }
  &_table {
    margin-bottom: 20px;
    color: grey;
    font-size: rem(19);
    @media screen and (min-width: 536px) {
      font-size: rem(21);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(20);
    }
    &_main--row {
      background-color: rgba(128, 128, 128, 0.5);
      font-family: $font_bold_1;
      padding: 10px;
      color: rgb(62, 61, 61);
    }
    &_cell {
      padding: 10px;
      border-bottom: 2px solid rgba(128, 128, 128, 0.2);
    }
  }
}

.highlight {
  font-family: $font_bold_1;
  color: $main-text-color;
}

.no_border {
  border-bottom: 0px;
}

.no_margin {
  margin-bottom: 0px;
}

.number {
  padding-bottom: 20px;
}

.privacidad_link:hover {
  color: $main-text-color;
}

.privacidad_link:active {
  color: $main-text-color;
}
