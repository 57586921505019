@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";

.areas {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  font-family: $font_bold_1;
  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  &_gaming {
    &_box {
      width: 100%;
      min-height: 140px;
      background-image: url("../../images/gaming.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: 4px solid red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
      @media screen and (min-width: 444px) {
        min-height: 170px;
      }
      @media screen and (min-width: 666px) {
        height: 200px;
      }
      @media screen and (min-width: 700px) {
        min-height: 110px;
        height: 130px;
        border-bottom: 2.5px solid red;
      }
      @media screen and (min-width: 830px) {
        min-height: 140px;
        height: 140px;
      }
      @media screen and (min-width: 907px) {
        min-height: 150px;
        height: 150px;
      }
      @media screen and (min-width: 1003px) {
        min-height: 160px;
        height: 160px;
      }
      @media screen and (min-width: 1067px) {
        min-height: 170px;
        height: 170px;
      }
      @media screen and (min-width: 1082px) {
        min-height: 180px;
        height: 180px;
      }
      @media screen and (min-width: 1100px) {
        min-height: 190px;
        height: 190px;
      }
      @media screen and (min-width: 1205px) {
        min-height: 200px;
        height: 200px;
      }
      @media screen and (min-width: 1399px) {
        min-height: 220px;
        height: 220px;
      }
      @media screen and (min-width: 1537px) {
        min-height: 250px;
        height: 250px;
      }
      @media screen and (min-width: 1748px) {
        min-height: 290px;
        height: 290px;
      }
    }
    &_text {
      text-align: center;
      text-transform: uppercase;
      padding-top: 8px;
      font-weight: 700;
      font-size: rem(17);
      color: white;
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 1187px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
  }
  &_espacios {
    &_box {
      width: 100%;
      min-height: 140px;
      background-image: url("../../images/espacios.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: 2px solid yellow;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
      @media screen and (min-width: 390px) {
        border-bottom: 3px solid yellow;
      }
      @media screen and (min-width: 393px) {
        border-bottom: 4px solid yellow;
      }
      @media screen and (min-width: 444px) {
        min-height: 170px;
        border-bottom: 2px solid yellow;
      }
      @media screen and (min-width: 467px) {
        min-height: 170px;
        border-bottom: 4px solid yellow;
      }
      @media screen and (min-width: 484px) {
        min-height: 170px;
        border-bottom: 5px solid yellow;
      }
      @media screen and (min-width: 666px) {
        height: 200px;
      }
      @media screen and (min-width: 700px) {
        min-height: 110px;
        height: 130px;
        border-bottom: 2.5px solid yellow;
      }
      @media screen and (min-width: 830px) {
        min-height: 140px;
        height: 140px;
      }
      @media screen and (min-width: 907px) {
        min-height: 150px;
        height: 150px;
      }
      @media screen and (min-width: 1003px) {
        min-height: 160px;
        height: 160px;
      }
      @media screen and (min-width: 1067px) {
        min-height: 170px;
        height: 170px;
      }
      @media screen and (min-width: 1082px) {
        min-height: 180px;
        height: 180px;
      }
      @media screen and (min-width: 1100px) {
        min-height: 190px;
        height: 190px;
      }
      @media screen and (min-width: 1205px) {
        min-height: 200px;
        height: 200px;
      }
      @media screen and (min-width: 1399px) {
        min-height: 220px;
        height: 220px;
      }
      @media screen and (min-width: 1537px) {
        min-height: 250px;
        height: 250px;
      }
      @media screen and (min-width: 1748px) {
        min-height: 290px;
        height: 290px;
      }
    }
    &_text_1 {
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 8px;
      font-weight: 700;
      font-size: rem(17);
      margin-bottom: -9px;
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 1187px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
    &_text_2 {
      padding-top: 0px;
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 8px;
      font-weight: 700;
      font-size: rem(17);
      margin-bottom: 0px;
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }

      @media screen and (min-width: 1187px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
  }
  &_estudio {
    &_box {
      width: 100%;
      min-height: 140px;
      background-image: url("../../images/estudio.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: 3px solid rgb(96, 170, 248);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
      @media screen and (min-width: 379px) {
        border-bottom: 4px solid rgb(96, 170, 248);
      }
      @media screen and (min-width: 444px) {
        min-height: 170px;
        border-bottom: 2px solid rgb(96, 170, 248);
      }
      @media screen and (min-width: 450px) {
        min-height: 170px;
        border-bottom: 4px solid rgb(96, 170, 248);
      }
      @media screen and (min-width: 455px) {
        min-height: 170px;
        border-bottom: 5px solid rgb(96, 170, 248);
      }
      @media screen and (min-width: 666px) {
        height: 200px;
      }
      @media screen and (min-width: 700px) {
        min-height: 110px;
        height: 130px;
        border-bottom: 2.5px solid rgb(69, 140, 215);
      }
      @media screen and (min-width: 830px) {
        min-height: 140px;
        height: 140px;
      }
      @media screen and (min-width: 907px) {
        min-height: 150px;
        height: 150px;
      }
      @media screen and (min-width: 1003px) {
        min-height: 160px;
        height: 160px;
      }
      @media screen and (min-width: 1067px) {
        min-height: 170px;
        height: 170px;
      }
      @media screen and (min-width: 1082px) {
        min-height: 180px;
        height: 180px;
      }
      @media screen and (min-width: 1100px) {
        min-height: 190px;
        height: 190px;
      }
      @media screen and (min-width: 1205px) {
        min-height: 200px;
        height: 200px;
      }
      @media screen and (min-width: 1399px) {
        min-height: 220px;
        height: 220px;
      }
      @media screen and (min-width: 1537px) {
        min-height: 250px;
        height: 250px;
      }
      @media screen and (min-width: 1748px) {
        min-height: 290px;
        height: 290px;
      }
    }
    &_text_1 {
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 3px;
      font-weight: 700;
      font-size: rem(17);
      margin-bottom: 0px;
      margin-bottom: -9px;
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }

      @media screen and (min-width: 1187px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
    &_text_2 {
      padding-top: 0px;
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 8px;
      font-weight: 700;
      font-size: rem(17);
      margin-bottom: 0px;
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }

      @media screen and (min-width: 1187px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
  }
  &_musica {
    &_box {
      width: 100%;
      min-height: 140px;
      background-image: url("../../images/musica.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: 4px solid orange;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
      @media screen and (min-width: 444px) {
        min-height: 170px;
        border-bottom: 5px solid orange;
      }
      @media screen and (min-width: 666px) {
        height: 200px;
      }
      @media screen and (min-width: 700px) {
        min-height: 110px;
        height: 130px;
        border-bottom: 2.5px solid orange;
      }
      @media screen and (min-width: 830px) {
        min-height: 140px;
        height: 140px;
      }
      @media screen and (min-width: 907px) {
        min-height: 150px;
        height: 150px;
      }
      @media screen and (min-width: 1003px) {
        min-height: 160px;
        height: 160px;
      }
      @media screen and (min-width: 1067px) {
        min-height: 170px;
        height: 170px;
      }
      @media screen and (min-width: 1082px) {
        min-height: 180px;
        height: 180px;
      }
      @media screen and (min-width: 1100px) {
        min-height: 190px;
        height: 190px;
      }
      @media screen and (min-width: 1205px) {
        min-height: 200px;
        height: 200px;
      }
      @media screen and (min-width: 1399px) {
        min-height: 220px;
        height: 220px;
      }
      @media screen and (min-width: 1537px) {
        min-height: 250px;
        height: 250px;
      }

      @media screen and (min-width: 1748px) {
        min-height: 290px;
        height: 290px;
      }
    }
    &_text {
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 8px;
      font-weight: 700;
      font-size: rem(17);
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 1187px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
  }
  &_entretenimiento {
    &_box {
      width: 100%;
      min-height: 140px;
      background-image: url("../../images/entretenimiento.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: 2px solid rgb(150, 198, 253);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
      @media screen and (min-width: 386px) {
        border-bottom: 3px solid rgb(150, 198, 253);
      }
      @media screen and (min-width: 393px) {
        border-bottom: 4px solid rgb(150, 198, 253);
      }
      @media screen and (min-width: 444px) {
        min-height: 170px;
        border-bottom: 2px solid rgb(150, 198, 253);
      }
      @media screen and (min-width: 466px) {
        min-height: 170px;
        border-bottom: 3px solid rgb(150, 198, 253);
      }
      @media screen and (min-width: 472px) {
        min-height: 170px;
        border-bottom: 4.5px solid rgb(150, 198, 253);
      }
      @media screen and (min-width: 666px) {
        height: 200px;
      }
      @media screen and (min-width: 700px) {
        min-height: 110px;
        height: 130px;
        border-bottom: 2.5px solid rgb(69, 140, 215);
      }
      @media screen and (min-width: 830px) {
        min-height: 140px;
        height: 140px;
      }
      @media screen and (min-width: 907px) {
        min-height: 150px;
        height: 150px;
      }
      @media screen and (min-width: 1003px) {
        min-height: 160px;
        height: 160px;
      }
      @media screen and (min-width: 1067px) {
        min-height: 170px;
        height: 170px;
      }
      @media screen and (min-width: 1082px) {
        min-height: 180px;
        height: 180px;
      }
      @media screen and (min-width: 1100px) {
        min-height: 190px;
        height: 190px;
      }
      @media screen and (min-width: 1205px) {
        min-height: 200px;
        height: 200px;
      }
      @media screen and (min-width: 1399px) {
        min-height: 220px;
        height: 220px;
      }
      @media screen and (min-width: 1537px) {
        min-height: 250px;
        height: 250px;
      }

      @media screen and (min-width: 1748px) {
        min-height: 290px;
        height: 290px;
      }
    }
    &_text {
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 8px;
      font-weight: 700;
      font-size: rem(17);
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }

      @media screen and (min-width: 1187px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
  }
  &_deporte {
    &_box {
      width: 100%;
      min-height: 140px;
      background-image: url("../../images/deporte.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: 3px solid rgb(247, 12, 220);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
      @media screen and (min-width: 386px) {
        border-bottom: 4px solid rgb(247, 12, 220);
      }
      @media screen and (min-width: 444px) {
        min-height: 170px;
      }
      @media screen and (min-width: 480px) {
        border-bottom: 5px solid rgb(247, 12, 220);
      }
      @media screen and (min-width: 666px) {
        height: 200px;
      }
      @media screen and (min-width: 700px) {
        min-height: 110px;
        height: 130px;
        border-bottom: 2.5px solid purple;
      }
      @media screen and (min-width: 830px) {
        min-height: 140px;
        height: 140px;
      }
      @media screen and (min-width: 907px) {
        min-height: 150px;
        height: 150px;
      }
      @media screen and (min-width: 1003px) {
        min-height: 160px;
        height: 160px;
      }
      @media screen and (min-width: 1067px) {
        min-height: 170px;
        height: 170px;
      }
      @media screen and (min-width: 1082px) {
        min-height: 180px;
        height: 180px;
      }
      @media screen and (min-width: 1100px) {
        min-height: 190px;
        height: 190px;
      }
      @media screen and (min-width: 1205px) {
        min-height: 200px;
        height: 200px;
      }
      @media screen and (min-width: 1399px) {
        min-height: 220px;
        height: 220px;
      }
      @media screen and (min-width: 1537px) {
        min-height: 250px;
        height: 250px;
      }
      @media screen and (min-width: 1748px) {
        min-height: 290px;
        height: 290px;
      }
    }
    &_text {
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 8px;
      font-weight: 700;
      font-size: rem(17);
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }

      @media screen and (min-width: 1187px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
  }
  &_amigos {
    &_box {
      width: 100%;
      min-height: 140px;
      background-image: url("../../images/amigos.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: 4px solid $main-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
      @media screen and (min-width: 444px) {
        min-height: 170px;
      }
      @media screen and (min-width: 452px) {
        min-height: 170px;
        border-bottom: 5px solid $main-color;
      }
      @media screen and (min-width: 467px) {
        min-height: 170px;
        border-bottom: 3.5px solid $main-color;
      }
      @media screen and (min-width: 469px) {
        min-height: 170px;
        border-bottom: 3.5px solid $main-color;
      }
      @media screen and (min-width: 480px) {
        border-bottom: 4.5px solid $main-color;
      }
      @media screen and (min-width: 666px) {
        height: 200px;
      }
      @media screen and (min-width: 700px) {
        min-height: 110px;
        height: 130px;
        border-bottom: 2.5px solid green;
      }
      @media screen and (min-width: 830px) {
        min-height: 140px;
        height: 140px;
      }
      @media screen and (min-width: 907px) {
        min-height: 150px;
        height: 150px;
      }
      @media screen and (min-width: 1003px) {
        min-height: 160px;
        height: 160px;
      }
      @media screen and (min-width: 1067px) {
        min-height: 170px;
        height: 170px;
      }
      @media screen and (min-width: 1082px) {
        min-height: 180px;
        height: 180px;
      }
      @media screen and (min-width: 1100px) {
        min-height: 190px;
        height: 190px;
      }
      @media screen and (min-width: 1205px) {
        min-height: 200px;
        height: 200px;
      }
      @media screen and (min-width: 1399px) {
        min-height: 220px;
        height: 220px;
      }
      @media screen and (min-width: 1537px) {
        min-height: 250px;
        height: 250px;
      }
      @media screen and (min-width: 1748px) {
        min-height: 290px;
        height: 290px;
      }
    }
    &_text_1 {
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 8px;
      font-weight: 700;
      font-size: rem(17);
      margin-bottom: -8px;
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }

      @media screen and (min-width: 1187px) {
        font-size: rem(25);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
    &_text_2 {
      padding-top: 0px;
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 8px;
      font-weight: 700;
      font-size: rem(17);
      margin-bottom: 0px;
      @media screen and (min-width: 446px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 476px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 562px) {
        font-size: rem(23);
      }
      @media screen and (min-width: 666px) {
        font-size: rem(28);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 962px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 1187px) {
        font-size: rem(24);
      }
      @media screen and (min-width: 1880px) {
        font-size: rem(26);
      }
    }
  }
  &_img_box {
    width: 100%;
    height: 100%;
  }
  &_img {
    width: 100%;
    height: 100%;
  }
}

.hero {
  &_text_4 {
    margin-bottom: 0px;
    padding-right: 7px;
    padding-left: 7px;
    font-size: rem(19);
    color: white;
    font-family: $font_regular_1;
    @media screen and (min-width: 446px) {
      font-size: rem(22);
    }
    @media screen and (min-width: 516px) {
      font-size: rem(25);
    }
    @media screen and (min-width: 562px) {
      font-size: rem(28);
    }
    @media screen and (min-width: 666px) {
      font-size: rem(30);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(17);
    }
    @media screen and (min-width: 815px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 868px) {
      font-size: rem(21);
    }
    @media screen and (min-width: 943px) {
      font-size: rem(23);
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(24);
    }
    @media screen and (min-width: 1045px) {
      font-size: rem(24);
    }
    @media screen and (min-width: 1131px) {
      font-size: rem(27);
    }
    @media screen and (min-width: 1187px) {
      font-size: rem(27);
    }
    @media screen and (min-width: 1254px) {
      font-size: rem(30);
    }
    @media screen and (min-width: 1425px) {
      font-size: rem(36);
    }
    @media screen and (min-width: 1525px) {
      font-size: rem(37);
    }
    @media screen and (min-width: 1537px) {
      font-size: rem(40);
    }
  }
  &_text_5 {
    margin-top: -11px;
    margin-bottom: 0px;
    font-size: rem(29);
    font-family: $font_bold_1;
    color: white;
    @media screen and (min-width: 446px) {
      margin-top: -13px;
      font-size: rem(32.5);
    }
    @media screen and (min-width: 516px) {
      margin-top: -13px;
      font-size: rem(36);
    }
    @media screen and (min-width: 562px) {
      margin-top: -17px;
      font-size: rem(41);
    }
    @media screen and (min-width: 666px) {
      margin-top: -15px;
      font-size: rem(42);
    }
    @media screen and (min-width: 700px) {
      margin-top: -11px;
      font-size: rem(25);
    }
    @media screen and (min-width: 815px) {
      margin-top: -10px;
      font-size: rem(26);
    }
    @media screen and (min-width: 868px) {
      margin-top: -9px;
      font-size: rem(27);
    }
    @media screen and (min-width: 874px) {
      margin-top: -9px;
      font-size: rem(27);
    }
    @media screen and (min-width: 943px) {
      margin-top: -10px;
      font-size: rem(30);
    }
    @media screen and (min-width: 1024px) {
      margin-top: -14px;
      font-size: rem(33);
    }
    @media screen and (min-width: 1045px) {
      margin-top: -13px;
      font-size: rem(33);
    }
    @media screen and (min-width: 1131px) {
      margin-top: -15px;
      font-size: rem(37);
    }
    @media screen and (min-width: 1187px) {
      margin-top: -15px;
      font-size: rem(37);
    }
    @media screen and (min-width: 1254px) {
      margin-top: -15px;
      font-size: rem(42);
    }
    @media screen and (min-width: 1425px) {
      margin-top: -18px;
      font-size: rem(48);
    }
    @media screen and (min-width: 1525px) {
      margin-top: -21px;
      font-size: rem(49);
    }
    @media screen and (min-width: 1537px) {
      margin-top: -21px;
      font-size: rem(52);
    }
  }
  &_text_6 {
    font-family: $font_bold_1;
    margin-top: -32px;
    margin-bottom: 3.5px;
    font-size: rem(64);
    color: white;
    @media screen and (min-width: 446px) {
      margin-top: -35px;
      font-size: rem(70);
    }
    @media screen and (min-width: 516px) {
      margin-top: -37px;
      font-size: rem(79);
    }
    @media screen and (min-width: 562px) {
      margin-top: -40px;
      font-size: rem(89);
    }
    @media screen and (min-width: 666px) {
      margin-top: -45px;
      font-size: rem(90);
    }
    @media screen and (min-width: 700px) {
      margin-top: -29px;
      font-size: rem(58);
    }
    @media screen and (min-width: 815px) {
      margin-top: -30px;
      font-size: rem(56);
    }
    @media screen and (min-width: 868px) {
      margin-top: -26px;
      font-size: rem(57);
    }
    @media screen and (min-width: 874px) {
      margin-top: -30px;
      font-size: rem(60);
    }
    @media screen and (min-width: 1024px) {
      margin-top: -37px;
      font-size: rem(72);
    }
    @media screen and (min-width: 1045px) {
      margin-top: -36px;
      font-size: rem(71);
    }
    @media screen and (min-width: 1131px) {
      margin-top: -40px;
      font-size: rem(79);
    }
    @media screen and (min-width: 1187px) {
      margin-top: -40px;
      font-size: rem(78);
    }
    @media screen and (min-width: 1254px) {
      margin-top: -42px;
      font-size: rem(87);
    }
    @media screen and (min-width: 1425px) {
      margin-top: -49px;
      font-size: rem(94);
    }
    @media screen and (min-width: 1525px) {
      margin-top: -54px;
      font-size: rem(99);
    }
    @media screen and (min-width: 1537px) {
      margin-top: -54px;
      font-size: rem(110);
    }
  }
}

.blank_space {
  opacity: 0.9;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $main-color;
  padding-top: 11px;
  @media screen and (min-width: 1288px) {
    padding-top: 15px;
  }
}
