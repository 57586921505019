@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";
@import "../core/keyframes.scss";

.header {
  width: 100%;
  min-height: 30px;
  height: 6vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  font-family: $font_bold_1;
  padding-top: 16px;
  position: fixed;
  z-index: 1;
  top: 0;
  @media screen and (min-width: 700px) {
    height: 7vh;
  }
  @media screen and (min-width: 1156px) {
    height: 8vh;
  }
  &__list {
    vertical-align: middle;
    margin-bottom: 0px;
    margin-right: 20px;
    display: flex;
    padding: 10px 15px;
    border-radius: 10px;
    height: 45px;
    @media screen and (min-width: 444px) {
      margin-right: 23px;
    }
    @media screen and (min-width: 700px) {
      margin-right: 60px;
      height: 45px;
    }
    @media screen and (min-width: 940px) {
      margin-right: 130px;
    }
    @media screen and (min-width: 1148px) {
      margin-right: 160px;
      height: 46px;
    }
    &--item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      animation: fadeIn-moveIn linear 1.5s;
    }
    &--link {
      font-family: $font_bold_1;
      text-decoration: none;
      font-weight: 800;
      font-size: rem(14);
      transition: 0.2s;
      cursor: pointer;
      @media screen and (min-width: 444px) {
        font-size: rem(15);
      }
      @media screen and (min-width: 549px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(17);
      }
      @media screen and (min-width: 940px) {
        font-size: rem(18);
      }
      @media screen and (min-width: 1148px) {
        font-size: rem(19);
      }
    }
  }
}

.header__list--link {
  color: $main-text-color;
}

.header__list--link:hover {
  color: $main-color;
}

.header__list--link:active {
  color: $main-color;
}

.servicios {
  padding-right: 20px;
  padding-left: 20px;
  border-right: 2px solid $main-color;
  border-left: 2px solid $main-color;
}

.contacto {
  padding-left: 20px;
}

.nosotros {
  padding-right: 20px;
}
