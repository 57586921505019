@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";

.cookies {
  &_main {
    font-family: $font_regular_1;
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 17px;
    padding-left: 17px;
    padding-bottom: 17px;
    padding-top: 20px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: rgba(226, 219, 219, 0.3);
    @media screen and (min-width: 700px) {
      margin-right: 80px;
      margin-left: 80px;
    }
    @media screen and (min-width: 1008px) {
      margin-right: 100px;
      margin-left: 100px;
      padding-right: 30px;
      padding-left: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @media screen and (min-width: 1248px) {
      margin-right: 200px;
      margin-left: 200px;
    }
  }
  &_main_title {
    color: $main-color;
    font-size: rem(30);
    padding-right: 22px;
    padding-left: 22px;
    margin-top: 75px;
    font-family: $font_bold_1;
    @media screen and (min-width: 700px) {
      padding-right: 0px;
      padding-left: 0px;
      margin-right: 55px;
      margin-left: 80px;
      font-size: rem(35);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(38);
      margin-top: 75px;
      margin-right: 100px;
      margin-left: 100px;
    }
    @media screen and (min-width: 1248px) {
      margin-right: 200px;
      margin-left: 200px;
      margin-top: 90px;
    }
  }
  &_secondary_title {
    color: rgb(141, 138, 138);
    font-size: rem(28);
    padding-bottom: 10px;
    font-family: $font_bold_1;
    @media screen and (min-width: 700px) {
      font-size: rem(27);
    }
  }
  &_text {
    color: $main-text-color;
    padding-bottom: 5px;
    font-size: rem(17);
    @media screen and (min-width: 450px) {
      font-size: rem(18);
    }
    @media screen and (min-width: 583px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(20);
    }
  }
  &_link {
    text-decoration: none;
    color: $main-color;
    font-family: $font_bold_1;
    &:hover {
      color: $main-text-color;
    }
    &:active {
      color: $main-text-color;
    }
  }
  &_ul_list {
    list-style: inside;
    font-size: rem(17);
    @media screen and (min-width: 450px) {
      font-size: rem(18);
    }
    @media screen and (min-width: 583px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(20);
    }
  }
  &_ul_list_2 {
    list-style: square;
    @media screen and (min-width: 700px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(20);
    }
  }
  &_ol_list {
    list-style: decimal;
    @media screen and (min-width: 450px) {
      font-size: rem(18);
    }
    @media screen and (min-width: 583px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(20);
    }
  }
  &_table {
    margin-bottom: 20px;
    font-size: rem(11);
    margin-right: 0px;
    margin-left: 0px;
    @media screen and (min-width: 450px) {
      font-size: rem(14);
    }
    @media screen and (min-width: 583px) {
      font-size: rem(16);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1008px) {
      font-size: rem(20);
    }
    &_main--row {
      background-color: rgba(128, 128, 128, 0.5);
      font-family: $font_bold_1;
      padding: 10px;
    }
    &_cell {
      padding: 10px;
      border-bottom: 2px solid rgba(128, 128, 128, 0.2);
    }
  }
}

li.no_style {
  list-style: none;
  padding: 0px;
  margin-bottom: 20px;
}

.collapsed {
  display: none;
}

li {
  margin-bottom: 15px;
}

.li_dif_style {
  padding-bottom: 10px;
}
