@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";
@import "../core/keyframes.scss";

.error_message {
  font-size: rem(20);
  margin-top: 15px;
  font-family: $font_bold_1;
  text-align: center;
  color: rgb(181, 3, 3);
  display: none;
}

.show_error {
  display: block;
}

.highlight_error {
  border: 2px solid rgb(181, 3, 3);
}
