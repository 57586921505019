@import "core/reset";
@import "core/page";
@import "core/functions";
@import "core/variables";
@import "~bootstrap/scss/bootstrap";
@import "core/bootstrapCustom";

.collapsed {
  display: none;
}
