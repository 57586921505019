@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";

@keyframes border_line {
  0% {
    border-width: 0px;
  }
  100% {
    border-width: 50px;
  }
}

@keyframes fadeIn-moveIn {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  100% {
    transform: translate(20, 50%);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes change_background {
  0% {
    background-color: $main-color;
  }
  100% {
    background-color: $main-text-color;
  }
  0% {
    background-color: $main-color;
  }
}
