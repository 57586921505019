@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";
@import "../core/keyframes.scss";

.embajadores {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 35px;
  font-family: $font_regular_1;
  @media screen and (min-width: 1060px) {
    width: 80%;
    margin-left: 120px;
  }
  @media screen and (min-width: 1225px) {
    width: 80%;
    margin-left: 130px;
  }
  @media screen and (min-width: 1332px) {
    width: 80%;
    margin-left: 150px;
  }
  @media screen and (min-width: 1462px) {
    width: 80%;
    margin-left: 160px;
  }
  @media screen and (min-width: 1499px) {
    width: 80%;
    margin-left: 170px;
  }
  @media screen and (min-width: 1640px) {
    width: 80%;
    margin-left: 175px;
  }
  &_header {
    position: relative;
    margin-top: 50px;
    &_img {
      width: 100%;
      height: 30vh;
      background-image: url("../../images/Header_embajadores_clean.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      /* min-height: 383px; */
      @media screen and (min-width: 700px) {
        height: 40vh;
      }
      @media screen and (min-width: 1389px) {
        height: 45vh;
      }
      @media screen and (min-width: 1510px) {
        height: 50vh;
      }
    }
    @media screen and (min-width: 700px) {
      margin-top: 57px;
    }
    @media screen and (min-width: 1156px) {
      margin-top: 72px;
    }
  }
  &_banner {
    opacity: 0.9;
    background-color: white;
    padding-top: 15px;
    padding-right: 2px;
    padding-left: 2px;
    width: 50.2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    z-index: 1;
    color: grey;
    font-size: rem(14);
    padding-bottom: 25px;
    @media screen and (min-width: 446px) {
      padding-top: 10px;
    }
    @media screen and (min-width: 562px) {
      padding-top: 13px;
    }
    @media screen and (min-width: 661px) {
      padding-top: 15px;
    }
    @media screen and (min-width: 700px) {
      left: 18%;
      width: 25%;
      padding-top: 10px;
      padding-bottom: 20px;
    }
    @media screen and (min-width: 799px) {
      width: 25%;
      padding-top: 9px;
    }
    @media screen and (min-width: 874px) {
      padding-top: 17px;
    }
    @media screen and (min-width: 1151px) {
      padding-top: 25px;
      padding-bottom: 26px;
    }
    @media screen and (min-width: 1257px) {
      padding-top: 30px;
      padding-bottom: 38px;
      font-size: rem(17);
    }
    @media screen and (min-width: 1271px) {
      padding-top: 37px;
      padding-bottom: 38px;
      font-size: rem(17);
    }
    @media screen and (min-width: 1433px) {
      padding-top: 40px;
      padding-bottom: 40px;
      font-size: rem(19);
    }
    @media screen and (min-width: 1698px) {
      padding-top: 38px;
      padding-bottom: 44px;
      font-size: rem(21);
    }
  }
  &_img_plataforma_box {
    width: 89%;
    padding-right: 5px;
    padding-left: 5px;
    @media screen and (min-width: 446px) {
      width: 84%;
      padding-top: 10px;
    }
    @media screen and (min-width: 700px) {
      width: 88%;
      padding-top: 10px;
    }
    @media screen and (min-width: 874px) {
      width: 85%;
      padding-top: 0px;
    }
    @media screen and (min-width: 1187px) {
      width: 80%;
      padding-top: 0px;
    }
  }
  &_img_plataforma {
    width: 100%;
    height: 100%;
  }
  &_title {
    font-family: $font_bold_1;
    color: $main-color;
    font-size: rem(30);
    text-align: center;
    padding-bottom: 25px;
    padding-right: 20px;
    padding-left: 20px;
    @media screen and (min-width: 500px) {
      font-size: rem(35);
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 30px;
    }
    @media screen and (min-width: 700px) {
      font-size: rem(32);
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 35px;
    }
    @media screen and (min-width: 921px) {
      padding-right: 50px;
      padding-left: 50px;
      padding-top: 20px;
    }
    @media screen and (min-width: 1004px) {
      padding-right: 80px;
      padding-left: 80px;
    }
    @media screen and (min-width: 1098px) {
      padding-right: 110px;
      padding-left: 110px;
    }
  }
  &_title_2 {
    font-family: $font_regular_1;
    color: $main-text-color;
    font-size: rem(21);
    text-align: center;
    padding-bottom: 20px;
    padding-right: 15px;
    padding-left: 15px;
    @media screen and (min-width: 500px) {
      font-size: rem(24);
      padding-right: 18px;
      padding-left: 18px;
    }
    @media screen and (min-width: 700px) {
      font-size: rem(20);
      padding-right: 25px;
      padding-left: 25px;
    }
    @media screen and (min-width: 1004px) {
      padding-right: 80px;
      padding-left: 80px;
    }
    @media screen and (min-width: 1098px) {
      padding-right: 110px;
      padding-left: 110px;
    }
  }
  &_title_3 {
    font-family: $font_bold_1;
    color: $main-text-color;
    font-size: rem(21);
    text-align: center;
    padding-bottom: 0px;
    padding-right: 15px;
    padding-left: 15px;
    @media screen and (min-width: 500px) {
      font-size: rem(25);
      padding-right: 18px;
      padding-left: 18px;
    }
    @media screen and (min-width: 700px) {
      font-size: rem(20);
      padding-right: 18px;
      padding-left: 18px;
    }
  }
  &_title_4 {
    font-family: $font_regular_1;
    color: $main-text-color;
    font-size: rem(21);
    text-align: center;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    @media screen and (min-width: 500px) {
      font-size: rem(25);
      padding-right: 18px;
      padding-left: 18px;
    }
    @media screen and (min-width: 700px) {
      font-size: rem(20);
      padding-right: 18px;
      padding-left: 18px;
    }
  }
  &_fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media screen and (min-width: 700px) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding-right: 20px;
    }
  }
  &_inputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    border-radius: 10px;
    padding-top: 20px;
    margin-bottom: 15px;
    padding-left: 20px;
    margin-left: 10px;
    margin-right: 10px;
    @media screen and (min-width: 612px) {
      align-items: flex-start;
      width: 100%;
    }
    @media screen and (min-width: 700px) {
      align-items: flex-start;
      margin-bottom: 10px;
    }
    @media screen and (min-width: 921px) {
      width: 600px;
    }
  }
  &_label {
    font-size: rem(23);
    font-family: $font_bold_1;
    color: $main-text-color;
    @media screen and (min-width: 700px) {
      font-size: rem(24);
    }
  }
  &_label_2 {
    font-size: rem(21);
    font-family: $font_regular_1;
    color: $main-text-color;
    @media screen and (min-width: 700px) {
      font-size: rem(22);
    }
  }
  &_input {
    opacity: 0.6;
    border: 1.5px solid rgba(128, 128, 128, 0.4);
    border-radius: 5%;
    padding: 10px;
    width: 93%;
    font-size: rem(18);
    @media screen and (min-width: 612px) {
      width: 100%;
    }
    &_radio {
      border: 1px solid $main-text-color;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      &--box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
      &:checked {
        background-color: $main-color;
      }
    }
  }
  &_message {
    opacity: 0.6;
    border: 1.5px solid rgba(128, 128, 128, 0.6);
    border-radius: 5%;
    padding: 10px;
    padding-bottom: 50px;
    padding-right: 150px;
    font-size: rem(15);
    @media screen and (min-width: 1024px) {
      padding-right: 145px;
      font-size: rem(18);
    }
  }
  &_submit {
    color: $main-color;
    border: 2px solid $main-color;
    border-radius: 10%;
    background: none;
    padding: 6px;
    margin-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 900;
    font-size: rem(20);
    font-family: $font_bold_1;
    cursor: pointer;
    @media screen and (min-width: 549px) {
      font-size: rem(18);
    }
    @media screen and (min-width: 612px) {
      font-size: rem(22);
    }
    @media screen and (min-width: 700px) {
      padding-top: 3px;
      padding-bottom: 5.9px;
      font-size: rem(24);
    }
  }
  &_link {
    text-decoration: none;
    font-family: $font_bold_1;
    color: $main-color;
  }
  &_contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    @media screen and (min-width: 444px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media screen and (min-width: 1194px) {
      width: 50%;
    }
    &_box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      text-align: start;
      font-size: rem(15);
      padding-left: 20px;
      @media screen and (min-width: 444px) {
        font-size: rem(16);
      }
      @media screen and (min-width: 447px) {
        font-size: rem(16);
        padding-left: 45px;
      }
      @media screen and (min-width: 455px) {
        font-size: rem(16);
        padding-left: 55px;
      }
      @media screen and (min-width: 463px) {
        font-size: rem(16);
        padding-left: 55px;
      }
      @media screen and (min-width: 532px) {
        font-size: rem(16);
        padding-left: 65px;
      }
      @media screen and (min-width: 549px) {
        padding-left: 40px;
      }
      @media screen and (min-width: 554px) {
        padding-left: 55px;
      }
      @media screen and (min-width: 651px) {
        padding-left: 90px;
      }
      @media screen and (min-width: 699px) {
        padding-left: 100px;
      }
      @media screen and (min-width: 700px) {
        padding-left: 13px;
        font-size: rem(13);
      }
    }
    &_text {
      opacity: 0.8;
      margin-bottom: 6px;
    }
    &_title_2 {
      font-size: rem(18);
      font-family: $font_bold_1;
      padding-bottom: 25px;
      padding-right: 10px;
      padding-left: 10px;
      color: $main-text-color;
      @media screen and (min-width: 455px) {
        padding-right: 20px;
        padding-left: 20px;
      }
      @media screen and (min-width: 463px) {
        padding-right: 18px;
        padding-left: 18px;
      }
      @media screen and (min-width: 532px) {
        padding-right: 25px;
        padding-left: 25px;
      }
      @media screen and (min-width: 549px) {
        font-size: rem(18);
      }
      @media screen and (min-width: 651px) {
        padding-right: 20px;
        padding-left: 20px;
        font-size: rem(18);
      }
      @media screen and (min-width: 699px) {
        padding-right: 25px;
        padding-left: 25px;
        font-size: rem(18);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(14);
        padding-left: 10px;
        text-align: start;
        padding-bottom: 4px;
      }
      @media screen and (min-width: 1024px) {
        font-size: rem(18);
        padding-left: 10px;
        text-align: start;
        padding-bottom: 4px;
        padding-right: 30px;
      }
    }
    &_link {
      font-size: rem(17);
      text-decoration: none;
      color: grey;
      cursor: pointer;
      &:hover {
        color: $main-color;
      }
    }
  }
}

.embajadores_2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 50px;
  font-family: $font_regular_1;
  animation: fadeIn-moveIn linear 2s;
}

.home {
  &_link {
    text-decoration: none;
    color: white;
    font-family: $font_bold_1;
    margin-top: 20px;
    font-size: rem(20);
    &:hover {
      color: $main-color;
    }
  }
  &_button {
    background-color: $main-color;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 10px;
    margin-top: 20px;
    &:hover {
      background-color: $main-text-color;
    }
  }
}

.form_link:hover {
  color: $main-text-color;
}

.embajadores_submit:hover {
  color: white !important;
  border: 2px solid white !important;
  background-color: $main-color;
}

.embajadores_input:focus {
  outline: none !important;
  border-color: $main-color;
  box-shadow: 0 0 10px $main-color;
}

.embajadores_input_radio:focus {
  outline: none !important;
  border-color: $main-color;
  box-shadow: 0 0 10px $main-color;
}

.titles {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &_main_title {
    font-size: rem(27);
    font-family: $font_bold_1;
    padding-bottom: 20px;
    color: $font_bold_1;
    color: $main-color;
    @media screen and (min-width: 500px) {
      font-size: rem(24);
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(29);
    }
  }
  &_main_text {
    font-size: rem(17);
    font-family: $font_bold_1;
    color: $font_bold_1;
    text-align: center;
    color: $main-text-color;
  }
}

.final {
  padding-bottom: 30px;
  @media screen and (min-width: 700px) {
    padding-right: 30px;
    padding-left: 30px;
  }
}

#test {
  width: 10px;
  height: 10px;
  border: 2px solid grey;
}

.collapsed {
  display: none;
}

.show {
  display: block;
}
