@import "../core/variables.scss";
@import "../core/mixins.scss";
@import "../core/keyframes.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 35px;
  font-family: $font_regular_1;
  @media screen and (min-width: 444px) {
    padding-bottom: 10px;
  }
  @media screen and (min-width: 700px) {
    padding-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
  @media screen and (min-width: 771px) {
    padding-left: 40px;
  }
  @media screen and (min-width: 830px) {
    padding-bottom: 0px;
    padding-right: 60px;
    padding-left: 60px;
  }
  &_info_captcha {
    color: grey;
    text-align: center;
    width: 75%;
    font-size: rem(16);
    margin-bottom: 40px;
    padding-top: 30px;
  }
  &_title {
    font-size: rem(28);
    font-family: $font_bold_1;
    padding-bottom: 35px;
    color: grey;
    @media screen and (min-width: 494px) {
      font-size: rem(30);
    }
    @media screen and (min-width: 598px) {
      font-size: rem(34);
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(35);
    }
  }
  &_title_2 {
    font-size: rem(28);
    font-family: $font_bold_1;
    text-align: center;
    padding-bottom: 35px;
    color: grey;
    @media screen and (min-width: 494px) {
      font-size: rem(30);
    }
    @media screen and (min-width: 598px) {
      font-size: rem(34);
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(35);
    }
  }
  &_fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media screen and (min-width: 700px) {
      gap: 20px;
    }
    @media screen and (min-width: 850px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  &_inputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 13px;
    width: 80%;
    margin-bottom: 30px;
    @media screen and (min-width: 700px) {
      align-items: flex-start;
      margin-bottom: 10px;
    }
    @media screen and (min-width: 850px) {
      width: 100%;
      padding-left: 9%;
    }
    @media screen and (min-width: 1014px) {
      width: 90%;
      padding-left: 9%;
    }
    @media screen and (min-width: 1092px) {
      width: 85%;
      padding-left: 9%;
    }
    @media screen and (min-width: 1141px) {
      width: 80%;
      padding-left: 12%;
    }
    @media screen and (min-width: 1194px) {
      width: 50%;
      padding-left: 12%;
    }
  }
  &_input {
    opacity: 0.6;
    border: 1.5px solid rgba(128, 128, 128, 0.6);
    border-radius: 5%;
    padding: 10px;
    width: 100%;
    font-size: rem(17);
    @media screen and (min-width: 477px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 598px) {
      font-size: rem(21);
    }
    @media screen and (min-width: 1024px) {
      width: 100%;
      font-size: rem(22);
    }
  }
  &_message {
    opacity: 0.6;
    border: 1.5px solid rgba(128, 128, 128, 0.6);
    border-radius: 5%;
    padding: 10px;
    padding-bottom: 50px;
    font-size: rem(17);
    width: 100%;
    margin-bottom: 5px;
    @media screen and (min-width: 477px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(22);
    }
  }
  &_submit {
    color: $main-color;
    border: 2px solid $main-color;
    border-radius: 10%;
    background: none;
    padding: 6px;
    padding-right: 18px;
    padding-left: 18px;
    font-weight: 900;
    font-size: rem(20);
    font-family: Albert_Bold;
    cursor: pointer;
    @media screen and (min-width: 549px) {
      font-size: rem(22);
    }
    @media screen and (min-width: 700px) {
      padding-top: 3px;
      padding-bottom: 5.9px;
      font-size: rem(22);
    }
    @media screen and (min-width: 850px) {
      font-size: rem(23);
    }
    @media screen and (min-width: 1024px) {
      padding-top: 3px;
      padding-bottom: 2.9px;
      font-size: rem(24);
    }
  }
  &_link {
    margin-top: 10px;
    text-decoration: none;
    font-family: $font_bold_1;
    color: $main-color;
    font-size: rem(19);
    @media screen and (min-width: 477px) {
      font-size: rem(21);
    }
    @media screen and (min-width: 549px) {
      font-size: rem(22);
    }
    @media screen and (min-width: 598px) {
      font-size: rem(23);
    }
    @media screen and (min-width: 679px) {
      font-size: rem(24);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(24);
      margin-top: 15px;
      padding-left: 25px;
    }
    @media screen and (min-width: 850px) {
      font-size: rem(21);
      margin-top: 15px;
      padding-left: 0px;
    }
  }
  &_contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    @media screen and (min-width: 444px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media screen and (min-width: 1194px) {
      width: 50%;
    }
    &_box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      text-align: start;
      font-size: rem(20);
      padding-left: 20px;
      @media screen and (min-width: 444px) {
        padding-left: 50px;
      }
      @media screen and (min-width: 447px) {
        padding-left: 45px;
      }
      @media screen and (min-width: 455px) {
        padding-left: 55px;
      }
      @media screen and (min-width: 463px) {
        padding-left: 55px;
      }
      @media screen and (min-width: 524px) {
        padding-left: 70px;
      }
      @media screen and (min-width: 549px) {
        padding-left: 90px;
      }
      @media screen and (min-width: 554px) {
        padding-left: 80px;
      }
      @media screen and (min-width: 600px) {
        padding-left: 80px;
      }
      @media screen and (min-width: 650px) {
        padding-left: 100px;
      }
      @media screen and (min-width: 700px) {
        padding-top: 15px;
        padding-left: 170px;
        font-size: rem(21);
      }
      @media screen and (min-width: 850px) {
        padding-top: 15px;
        padding-left: 10px;
        font-size: rem(21);
      }
    }
    &_box_2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      text-align: start;
      font-size: rem(20);
      padding-left: 50px;
      margin-top: 20px;
      @media screen and (min-width: 444px) {
        padding-left: 50px;
      }
      @media screen and (min-width: 447px) {
        padding-left: 50px;
      }
      @media screen and (min-width: 455px) {
        padding-left: 50px;
      }
      @media screen and (min-width: 463px) {
        padding-left: 60px;
      }
      @media screen and (min-width: 524px) {
        padding-left: 60px;
      }
      @media screen and (min-width: 549px) {
        padding-left: 70px;
      }
      @media screen and (min-width: 554px) {
        padding-left: 70px;
      }
      @media screen and (min-width: 600px) {
        padding-left: 70px;
      }
      @media screen and (min-width: 650px) {
        padding-left: 80px;
      }
      @media screen and (min-width: 700px) {
        padding-top: 15px;
        padding-left: 80px;
        font-size: rem(21);
      }
      @media screen and (min-width: 850px) {
        padding-top: 15px;
        padding-left: 70px;
        font-size: rem(21);
      }
    }
    &_text {
      opacity: 0.8;
      margin-bottom: 6px;
    }
    &_title_2 {
      font-size: rem(19);
      font-family: $font_bold_1;
      padding-bottom: 25px;
      padding-right: 10px;
      padding-left: 10px;
      color: $main-text-color;
      @media screen and (min-width: 455px) {
        padding-right: 20px;
        padding-left: 20px;
      }
      @media screen and (min-width: 463px) {
        padding-right: 18px;
        padding-left: 18px;
        font-size: rem(20);
      }
      @media screen and (min-width: 532px) {
        padding-right: 25px;
        padding-left: 25px;
        font-size: rem(20.5);
      }
      @media screen and (min-width: 651px) {
        padding-right: 30px;
        padding-left: 30px;
        font-size: rem(21);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(22);
        padding-left: 20px;
        padding-bottom: 4px;
      }
      @media screen and (min-width: 830px) {
        font-size: rem(23);
        padding-left: 20px;
        padding-bottom: 4px;
      }
      @media screen and (min-width: 850px) {
        font-size: rem(21);
        text-align: start;
        padding-left: 10px;
        padding-bottom: 4px;
      }
      @media screen and (min-width: 1024px) {
        font-size: rem(22);
        padding-left: 10px;
        text-align: start;
        padding-bottom: 0px;
        padding-right: 30px;
      }
    }
    &_title_3 {
      font-size: rem(20);
      font-family: $font_bold_1;
      padding-bottom: 8px;
      padding-right: 10px;
      padding-left: 10px;
      color: $main-text-color;
      text-align: center;
      @media screen and (min-width: 455px) {
        padding-right: 20px;
        padding-left: 20px;
      }
      @media screen and (min-width: 463px) {
        padding-right: 18px;
        padding-left: 18px;
        font-size: rem(20);
      }
      @media screen and (min-width: 532px) {
        padding-right: 25px;
        padding-left: 25px;
        font-size: rem(20.5);
      }
      @media screen and (min-width: 651px) {
        padding-right: 30px;
        padding-left: 30px;
        font-size: rem(21);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(22);
        padding-left: 20px;
        padding-bottom: 4px;
      }
      @media screen and (min-width: 830px) {
        font-size: rem(23);
        padding-left: 20px;
        padding-bottom: 4px;
      }
      @media screen and (min-width: 850px) {
        font-size: rem(21);
        text-align: start;
        padding-left: 10px;
        padding-bottom: 4px;
      }
      @media screen and (min-width: 1024px) {
        font-size: rem(22);
        padding-left: 10px;
        text-align: start;
        padding-bottom: 0px;
        padding-right: 30px;
      }
    }
    &_link {
      font-size: rem(19);
      text-decoration: none;
      color: grey;
      padding-left: 3px;
      cursor: pointer;
      &:hover {
        color: $main-color;
      }
      @media screen and (min-width: 479px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 700px) {
        font-size: rem(21);
      }
      @media screen and (min-width: 830px) {
        font-size: rem(22);
      }
      @media screen and (min-width: 850px) {
        font-size: rem(20);
      }
      @media screen and (min-width: 1024px) {
        font-size: rem(22);
      }
    }
  }
}

.form_2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 35px;
  font-family: $font_regular_1;
  animation: fadeIn-moveIn linear 3s;
  @media screen and (min-width: 444px) {
    padding-bottom: 10px;
  }
  @media screen and (min-width: 700px) {
    padding-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
  @media screen and (min-width: 771px) {
    padding-left: 40px;
  }
  @media screen and (min-width: 830px) {
    padding-bottom: 0px;
    padding-right: 60px;
    padding-left: 60px;
  }
}

.fa-envelope,
.fa-mobile-screen,
.fa-location-dot {
  color: $main-color;
  opacity: 0.8;
  padding-right: 10px;
}

.fa-envelope {
  padding-right: 10px;
  position: relative;
  right: 0.5%;
  @media screen and (min-width: 700px) {
    right: 0.7%;
  }
}

.fa-mobile-screen {
  padding-right: 13px;
}

.fa-location-dot {
  padding-right: 13px;
}

.form_link:hover {
  color: $main-text-color;
}

.form_submit:hover {
  color: white !important;
  border: 2px solid white !important;
  background-color: $main-color;
}

.form_input:focus,
.form_message:focus {
  outline: none !important;
  border-color: $main-color;
  box-shadow: 0 0 10px $main-color;
}
