@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";
@import "../core/bootstrapCustom.scss";

.carousel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
  background-color: rgba(128, 128, 128, 0.2);
  @media screen and (min-width: 629px) {
    padding-bottom: 30px;
  }
  @media screen and (min-width: 700px) {
    padding-bottom: 33px;
  }
  &_title {
    padding-top: 25px;
    padding-bottom: 30px;
    color: grey;
    font-size: rem(26);
    @media screen and (min-width: 549px) {
      font-size: rem(27);
    }
    @media screen and (min-width: 629px) {
      font-size: rem(30);
    }
    @media screen and (min-width: 700px) {
      font-size: rem(32);
      padding-bottom: 34px;
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(30);
    }
  }
  &__images {
    &__box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      @media screen and (min-width: 700px) {
        gap: 15px;
      }
      @media screen and (min-width: 800px) {
        gap: 25px;
      }
    }
    &__card {
      width: 70px;
      height: 70px;
      @media screen and (min-width: 549px) {
        width: 90px;
        height: 90px;
      }
      @media screen and (min-width: 700px) {
        width: 85px;
        height: 85px;
      }
      @media screen and (min-width: 930px) {
        width: 110px;
        height: 110px;
      }
      @media screen and (min-width: 1110px) {
        width: 120px;
        height: 120px;
      }
    }
    &__image {
      width: 100%;
      height: 100%;
    }
  }
}

//special styles for logos
.milka {
  width: 97px;
  height: 50px;
  @media screen and (min-width: 549px) {
    width: 120px;
    height: 60px;
  }
  @media screen and (min-width: 1190px) {
    width: 120px;
    height: 60px;
  }
}

.eag {
  position: relative;
  right: 5px;
  @media screen and (min-width: 700px) {
    left: 13px;
    top: 0;
  }
  @media screen and (min-width: 1190px) {
    left: -5px;
    top: 0;
  }
}

.ultima {
  width: 95px;
  height: 45px;
  position: relative;
  left: 15px;
  @media screen and (min-width: 700px) {
    width: 110px;
    left: -20px;
    height: 50px;
    bottom: 8px;
  }
  @media screen and (min-width: 830px) {
    width: 120px;
    height: 55px;
  }
  @media screen and (min-width: 930px) {
    width: 140px;
    height: 65px;
  }
  @media screen and (min-width: 1190px) {
    left: -5px;
    width: 120px;
    height: 54px;
  }
}

.halls {
  height: 25px;
  @media screen and (min-width: 549px) {
    height: 30px;
    top: 35px;
  }
  @media screen and (min-width: 994px) {
    height: 30px;
    top: 40px;
  }
  @media screen and (min-width: 1190px) {
    top: 49px;
  }
}

.trident {
  width: 90px;
  height: 70px;
  position: relative;
  right: 5px;
  @media screen and (min-width: 614px) {
    width: 120px;
    height: 90px;
  }
  @media screen and (min-width: 700px) {
    right: 0;
    top: 0;
    left: 10px;
    width: 100px;
    height: 75px;
  }
  @media screen and (min-width: 1190px) {
    right: 0;
    top: 0px;
    left: 0px;
    width: 85px;
    height: 70px;
  }
  @media screen and (min-width: 1455px) {
    width: 100px;
    height: 75px;
  }
}

.amazon {
  height: 32px;
  width: 105px;
  position: relative;
  left: 10px;
  top: 5px;
  @media screen and (min-width: 700px) {
    top: 0px;
    left: 40px;
  }
  @media screen and (min-width: 830px) {
    width: 120px;
    height: 38px;
  }
  @media screen and (min-width: 930px) {
    width: 140px;
    height: 45px;
  }
  @media screen and (min-width: 1190px) {
    top: 5px;
    left: 0px;
    width: 100px;
    height: 30px;
  }
  @media screen and (min-width: 1455px) {
    width: 140px;
    height: 40px;
  }
}

//carousel specific styles
.carousel-inner .carousel-item {
  transition: -webkit-transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out, -webkit-transform 1s ease-in-out;
}

.carousel.slide {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgba(226, 221, 221, 0.2);
  @media screen and (min-width: 432px) {
    width: 89%;
  }
  @media screen and (min-width: 497px) {
    width: 80%;
  }
  @media screen and (min-width: 516px) {
    width: 83%;
  }
  @media screen and (min-width: 549px) {
    width: 89%;
  }
  @media screen and (min-width: 581px) {
    width: 86%;
  }
  @media screen and (min-width: 610px) {
    width: 82%;
  }
  @media screen and (min-width: 663px) {
    width: 80%;
  }
  @media screen and (min-width: 700px) {
    width: 100%;
  }
  @media screen and (min-width: 715px) {
    width: 85%;
  }
  @media screen and (min-width: 777px) {
    width: 85%;
  }
  @media screen and (min-width: 809px) {
    width: 80%;
  }
  @media screen and (min-width: 887px) {
    width: 80%;
  }
  @media screen and (min-width: 930px) {
    width: 82%;
  }
  @media screen and (min-width: 982px) {
    width: 80%;
  }
  @media screen and (min-width: 994px) {
    width: 80%;
  }
  @media screen and (min-width: 1010px) {
    width: 80%;
  }
  @media screen and (min-width: 1040px) {
    width: 77%;
  }
  @media screen and (min-width: 1116px) {
    width: 76%;
  }
  @media screen and (min-width: 1190px) {
    width: 100%;
  }
  @media screen and (min-width: 1307px) {
    width: 95%;
  }
  @media screen and (min-width: 1383px) {
    width: 92%;
  }
  @media screen and (min-width: 1534px) {
    width: 89%;
  }
  @media screen and (min-width: 1586px) {
    width: 85%;
  }
  @media screen and (min-width: 1659px) {
    width: 80%;
  }
  @media screen and (min-width: 1749px) {
    width: 75%;
  }
  @media screen and (min-width: 1788px) {
    width: 73%;
  }
}

//Flechas para moverse entre fotos
.carousel-control-prev-icon {
  transform: scale(0.8);
  position: relative;
  bottom: 17%;
}
.carousel-control-next-icon {
  transform: scale(0.8);
  position: relative;
  bottom: 17%;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}

.carousel-indicators {
  padding-top: 20px;
  margin: 0;
  z-index: 2;
}

.carousel-indicators button {
  width: 0.5rem !important;
  height: 0.5rem !important;
  border-radius: 50%;
  background-color: black;
  transform: scale(1.3);
}

//Botones para moverse entre fotos
.carousel-indicators {
  visibility: hidden;
}

.carousel-indicators button:not(:first-child) {
  margin-left: 0.5rem;
  background-color: green;
}

.carousel-indicators button:first-child {
  margin-left: 0.5rem;
  background-color: green;
}
