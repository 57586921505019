@font-face {
  font-family: Albert_Bold;
  src: url("../../fonts/FS Albert Pro-Bold.otf") format("opentype");
}
@font-face {
  font-family: Albert_Thin;
  src: url("../../fonts/FS Albert Pro-Thin.otf") format("opentype");
}
@font-face {
  font-family: Albert_Light;
  src: url("../../fonts/FS Albert Pro-Light.otf") format("opentype");
}

//Fonts
$defaultFontSize: 16;
$main-font: "Merriweather", serif;
$secondary-font: "Raleway", sans-serif;
$font_bold_1: Albert_Bold;
$font_thin_1: Albert_Thin;
$font_regular_1: Albert_Light;

//Colors
$main-text-color: #6f6f6e;
$main-color: #b6c932;
