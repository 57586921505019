@import "../core/functions.scss";
@import "../core/variables.scss";
@import "../core/mixins.scss";

.razones {
  width: 100%;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  font-family: $font_bold_1;
  @media screen and (min-width: 444px) {
    padding-right: 25px;
    padding-left: 25px;
  }
  @media screen and (min-width: 700px) {
    min-height: 550px;
    padding-bottom: 20px;
    padding-right: 13px;
    padding-left: 13px;
  }
  @media screen and (min-width: 769px) {
    padding-right: 18px;
    padding-left: 18px;
    padding-bottom: 30px;
  }
  @media screen and (min-width: 830px) {
    padding-right: 50px;
    padding-left: 50px;
  }
  @media screen and (min-width: 1014px) {
    padding-right: 80px;
    padding-left: 80px;
    padding-bottom: 40px;
  }
  @media screen and (min-width: 1024px) {
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 35px;
  }
  @media screen and (min-width: 1277px) {
    padding-right: 120px;
    padding-left: 120px;
    background-image: url("../../images/Mano 2 fondo blanco.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media screen and (min-width: 1352px) {
    padding-right: 250px;
    padding-left: 250px;
  }
  @media screen and (min-width: 1352px) {
    padding-right: 170px;
    padding-left: 170px;
  }
  @media screen and (min-width: 1430px) {
    padding-right: 200px;
    padding-left: 200px;
  }
  @media screen and (min-width: 1477px) {
    padding-right: 220px;
    padding-left: 220px;
  }
  @media screen and (min-width: 1521px) {
    padding-right: 240px;
    padding-left: 240px;
  }
  @media screen and (min-width: 1543px) {
    padding-right: 260px;
    padding-left: 260px;
  }
  @media screen and (min-width: 1664px) {
    padding-right: 290px;
    padding-left: 290px;
  }
  @media screen and (min-width: 1744px) {
    padding-right: 320px;
    padding-left: 320px;
  }
  @media screen and (min-width: 1822px) {
    padding-right: 360px;
    padding-left: 360px;
  }
  &_image {
    width: 100%;
    height: 100%;
    &_box {
      border: 2px solid blue;
      &_nodos {
        width: 39px;
        height: 40px;
        margin-right: 12px;
      }
      &_reloj {
        width: 35px;
        height: 35px;
        margin-right: 12px;
      }
      &_map {
        width: 40px;
        height: 33px;
        margin-right: 12px;
      }
      &_24 {
        width: 35px;
        height: 35px;
        margin-right: 12px;
      }
      &_360 {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
      &_dart {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
    }
  }
  &_box {
    @media screen and (min-width: 700px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  &_section_box {
    padding-right: 10px;
    padding-left: 5px;
  }
  &_title {
    font-size: rem(30);
    color: rgb(90, 88, 88);
    padding-bottom: 40px;
    padding-top: 15px;
    text-align: center;
    @media screen and (min-width: 444px) {
      font-size: rem(31);
    }
    @media screen and (min-width: 579px) {
      font-size: rem(33);
      padding-top: 25px;
      padding-bottom: 45px;
    }
    @media screen and (min-width: 700px) {
      font-size: rem(34);
      padding-bottom: 53px;
    }
    @media screen and (min-width: 769px) {
      font-size: rem(34);
      padding-bottom: 60px;
    }
    @media screen and (min-width: 830px) {
      font-size: rem(35);
    }
    @media screen and (min-width: 1014px) {
      padding-bottom: 70px;
    }
    @media screen and (min-width: 1024px) {
      padding-bottom: 55px;
      padding-top: 39px;
    }
    @media screen and (min-width: 1277px) {
      padding-bottom: 75px;
      padding-top: 45px;
    }
    @media screen and (min-width: 1352px) {
      padding-bottom: 75px;
    }
    @media screen and (min-width: 1430px) {
      padding-bottom: 80px;
    }
    @media screen and (min-width: 1477px) {
      padding-bottom: 85px;
    }
    @media screen and (min-width: 1521px) {
      padding-bottom: 75px;
    }
    @media screen and (min-width: 1543px) {
      padding-bottom: 80px;
    }
  }
  &_title_2 {
    font-size: rem(20);
    color: white;
    margin-bottom: 0px;
    background-image: url("../../images/Subrayado verde.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5px;
    padding-left: 8px;
    flex: 1;
    @media screen and (min-width: 444px) {
      font-size: rem(21);
    }
    @media screen and (min-width: 502px) {
      padding-left: 10px;
    }
    @media screen and (min-width: 579px) {
      font-size: rem(23);
      padding-left: 19px;
    }
    @media screen and (min-width: 700px) {
      font-size: rem(20);
      margin-bottom: 2px;
      padding-left: 11px;
    }
    @media screen and (min-width: 830px) {
      font-size: rem(21);
      padding-left: 8px;
    }
    @media screen and (min-width: 1014px) {
      padding-left: 7px;
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(21);
      padding-left: 15px;
    }
    @media screen and (min-width: 1352px) {
      padding-left: 17px;
    }
  }
  &_text {
    font-size: rem(19);
    color: grey;
    font-family: $font_bold_1;
    padding-bottom: 30px;
    padding-top: 8px;
    margin-left: 52px;
    @media screen and (min-width: 444px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 579px) {
      font-size: rem(22);
      padding-left: 9px;
    }
    @media screen and (min-width: 700px) {
      font-size: rem(20);
      margin-bottom: 2px;
      padding-left: 1px;
    }
    @media screen and (min-width: 769px) {
      font-size: rem(20);
      margin-bottom: 2px;
      padding-left: 0px;
    }
    @media screen and (min-width: 830px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(20);
      padding-left: 3px;
      padding-bottom: 60px;
    }
    @media screen and (min-width: 1277px) {
      padding-left: 7px;
    }
  }
  &_text_1 {
    font-size: rem(19);
    color: grey;
    font-family: $font_bold_1;
    padding-bottom: 0px;
    padding-top: 8px;
    margin-left: 52px;
    @media screen and (min-width: 444px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 579px) {
      font-size: rem(22);
      padding-left: 9px;
    }
    @media screen and (min-width: 700px) {
      font-size: rem(20);
      margin-bottom: 2px;
      padding-left: 1px;
    }
    @media screen and (min-width: 769px) {
      font-size: rem(20);
      margin-bottom: 10px;
      padding-left: 1px;
    }
    @media screen and (min-width: 830px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 1277px) {
      padding-left: 7px;
    }
  }
  &_text_2 {
    font-size: rem(19);
    padding-bottom: 5px;
    padding-top: 0px;
    color: grey;
    font-family: $font_bold_1;
    margin-left: 52px;
    @media screen and (min-width: 444px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 579px) {
      font-size: rem(22);
      padding-left: 9px;
    }
    @media screen and (min-width: 700px) {
      font-size: rem(20);
      margin-bottom: 30px;
      padding-left: 1px;
    }
    @media screen and (min-width: 830px) {
      font-size: rem(19);
    }
    @media screen and (min-width: 1024px) {
      font-size: rem(20);
    }
    @media screen and (min-width: 1277px) {
      padding-left: 7px;
    }
  }
}

.highlight_green {
  color: $main-color;
}

.uppercase {
  text-transform: uppercase;
}

.title_box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
